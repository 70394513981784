import React from "react";
import Image from "next/image";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled, Theme } from "@mui/material/styles";
import { SizeProps, UIModalProps } from "./UIModalProps";

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // Estilo para el backdrop para hacerlo casi blanco
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(12, 17, 29, 0.70)",
    backdropFilter: "blur(4px)",
  },
}));

const Paper = styled("div")<{ theme?: Theme; size?: SizeProps }>(({ size }) => ({
    position: "absolute",
    width: size?.width || "504px",
    height: size?.height || "292px",
    backgroundColor: "white",
    boxShadow: '0px 24px 48px -12px rgba(16, 24, 40, 0.18);',
    padding: "16px 32px 24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "16px"
}));

const Header = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: "16px",
  position: "relative",
});

const LogoContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "48px",
  height: "48px",
  border: "1px solid #EAECF0",
  borderRadius: "10px",
});

const Logo = styled("img")({
  width: "36px",
  height: "36px",
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: 0,
  right: 0,
  width: "44px",
  height: "44px",
});

const StyledCloseIcon = styled(CloseIcon)({
  color: "rgba(0, 0, 0, 0.6)",
  width: "24px",
  height: "24px",
});

const Body = styled("div")({
  marginBottom: "16px",
});

const Footer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  gap: "10px",
});

const TitleContent = styled('div')({
  marginBottom: '0px',
});


const UIModal: React.FC<UIModalProps> = ({ open, onClose, titleContent, logo, body, footer, size, headerStyles, bodyStyles, footerStyles, noClose}) => {
  const handleClose = (event: {}, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      onClose();
    }
  };
  return (
    <StyledModal open={open} onClose={handleClose}>
      {/* <Paper size={size}>
        <Header>
          <LogoContainer>
            <Logo src={logo} alt="Logo" />
          </LogoContainer>
          <CloseButton onClick={onClose}>
            <StyledCloseIcon />
          </CloseButton>
        </Header>
        <Body sx={{ overflow: size?.overflow ?? "auto" }}>{body}</Body>
        <Footer>{footer}</Footer>
      </Paper> */}


      <Paper size={size} sx={{ maxHeight: '80%', overflow: 'auto' }}>     
            <Header sx={headerStyles}>
               { logo && ( <LogoContainer> <Logo src={logo} alt="Logo" /> </LogoContainer> ) }
               {  titleContent && ( <TitleContent> {titleContent}  </TitleContent>)  }
               {(noClose == undefined || !noClose ) && <CloseButton onClick={onClose}>
                  <Image src="/assets/icons/x-close.svg" alt={"Icono siguiente"} width="20" height="20" />
               </CloseButton>}
           </Header>
                {  body && (  <Body sx={bodyStyles}>{body}</Body> ) }
           <Footer sx={footerStyles ? footerStyles : headerStyles }>
               {footer}
           </Footer>
       </Paper>

    </StyledModal>
  );
};

export default UIModal;
